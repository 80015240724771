import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AvailabilityCard from './AvailabilityCard';
import { motion } from 'framer-motion';
import '../../styles/availability/availability.scss';

const CARDS_PER_ROW = 3;
const NUM_ROWS = 1

const AvailabilitySection = () => {
    const [units, setUnits] = useState([]);
    const [filteredUnits, setFilteredUnits] = useState([]);
    const [visibleUnitsCount, setVisibleUnitsCount] = useState(CARDS_PER_ROW * NUM_ROWS);
    const [moveInDate, setMoveInDate] = useState(new Date());
    const [bathFilter, setBathFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const [locationOptions, setLocationOptions] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading
  
    useEffect(() => {
      // Fetch data from API
      const table_name = 'property_availability'
      const apiUrl = `https://us-central1-dot-house-automation.cloudfunctions.net/get_table_data?table=${table_name}`;
      axios.get(apiUrl)
        .then(response => {
          const filteredData = response.data.filter(unit => (unit.earliest_vacancy !== 'AtWill' && (unit.portfolio === 'Ending in Aug' || unit.portfolio === 'Final houses') && unit.is_active === 1));
          setUnits(filteredData);
          const uniqueLocations = [...new Set(filteredData.flatMap(unit => unit.location_filter.split(',').map(loc => loc.trim())))];
          setLocationOptions(uniqueLocations);
          filterUnits(filteredData, moveInDate, bathFilter, locationFilter);
          setLoading(false); // Set loading to false after data is fetched
        })
        .catch(error => {
          console.error('Error fetching units:', error);
        });
    }, []);
  
    useEffect(() => {
      filterUnits(units, moveInDate, bathFilter, locationFilter);
    }, [moveInDate, bathFilter, locationFilter]);
  
    const filterUnits = (units, moveInDate, bathFilter, locationFilter) => {
      let filtered = units;
  
      if (moveInDate) {
        filtered = filtered.filter(unit => new Date(unit.earliest_vacancy) <= moveInDate);
      }
  
      if (bathFilter === 'Private') {
        filtered = filtered.filter(unit => unit.baths === '1');
      } else if (bathFilter === 'Shared') {
        filtered = filtered.filter(unit => unit.baths !== '1');
      }

      if (locationFilter) {
        filtered = filtered.filter(unit => unit.location_filter.split(',').map(loc => loc.trim()).includes(locationFilter));
      }
  
      filtered.sort((a, b) => new Date(a.earliest_vacancy) - new Date(b.earliest_vacancy));
  
      setFilteredUnits(filtered);
    };

    const handleSeeMore = () => {
      setVisibleUnitsCount(prevCount => prevCount + CARDS_PER_ROW * NUM_ROWS); // Show 3 more rows
    };

    if (loading) {
      return <div className="loading-message">Loading Availability...</div>;
    }

    return (
      <div>
        <div id='availability-layout'>
            <div id='availability-title-layout'>
                <h1 style={{ marginRight: '10px' }} id='availability-title'> Colive Houses in Austin, TX </h1>
            </div>
            <div className="filters">
                <div className="filter">
                  <label>Move in Date:</label>
                  <DatePicker id="date-picker" className="filter-input" selected={moveInDate} onChange={date => setMoveInDate(date)} />
                </div>
                <div className="filter">
                  <label>Bath:</label>
                  <select className="filter-input" value={bathFilter} onChange={e => setBathFilter(e.target.value)}>
                      <option value="">All</option>
                      <option value="Private">Private</option>
                      <option value="Shared">Shared</option>
                  </select>
                </div>
                <div className="filter">
                  <label>Location:</label>
                  <select className="filter-input" value={locationFilter} onChange={e => setLocationFilter(e.target.value)}>
                    <option value="">All</option>
                    {locationOptions.map(location => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                </div>
            </div>
        </div>
        <div id='unit-grid-layout'>
          {filteredUnits.length === 0 ? (
            <div className="no-units-message">No Units Found</div>
          ) : (
            <div id="unit-grid" className="unit-grid">
              {filteredUnits.slice(0, visibleUnitsCount).map(unit => (
                <AvailabilityCard key={unit.property_name + "::" + unit.unit_name} unit={unit} />
              ))}
            </div>
          )}
          {visibleUnitsCount < filteredUnits.length && (
            <div className="see-more">
              <motion.a 
                  id="title"
                  activeClass="active"
                  offset={-115}
                  spy={true}
                  smooth={true}
                  duration={500}
                  target="_blank"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  whileInView={{ opacity: 1 }}>
                <button onClick={handleSeeMore}>See More</button>
              </motion.a>
            </div>
          )}
        </div>
      </div>
    );
}


export default AvailabilitySection;