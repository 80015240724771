import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import HomePage from './components/homepage/Homepage';


const root = ReactDOM.createRoot(
    document.getElementById("root")
);

const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/homepage",
          element: <HomePage />
        }
      ],
    },
]);



root.render (
    <div>
      <RouterProvider router={router} />
    </div>
);