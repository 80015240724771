import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import '../../styles/navbar/navbar.scss';

const variants = {
    show: {
        transform: "translateX(0em)",
        opacity: 1,
    },
    hide: {
        transform: "translateX(5em)",
        opacity: 0,
    },
};


const MenuContent = ({ isOpen }) => {

    return (
        <nav id='nav-menu-layout' aria-label='tabpanel'>
            <ul id='nav-list' aria-label='navigation tablist'>
                <motion.li  
                    initial={false}
                    animate={isOpen ? "show" : "hide"}
                    variants={{
                        show: {
                            ...variants.show,
                            transition: { delay: 0.3, duration: 0.2 },
                        },
                        hide: {
                            ...variants.hide,
                            transition: { delay: 0.05, duration: 0.05 },
                        },
                    }}
                    className="nav-link"
                    aria-label='link'   
                >
                    <Link
                        tabIndex={0}
                        role='link'
                        className='nav-links'
                        aria-label='menuitem tab'
                        activeClass="active"
                        to="availability-layout"
                        spy={true}
                        offset={-60}
                        smooth={true}
                        duration={500}
                    >
                        Rent
                    </Link>
                </motion.li>
                <motion.li
                    initial={false}
                    animate={isOpen ? "show" : "hide"}
                    variants={{
                        show: {
                            ...variants.show,
                            transition: { delay: 0.3, duration: 0.2 },
                        },
                        hide: {
                            ...variants.hide,
                            transition: { delay: 0.05, duration: 0.05 },
                        },
                    }}
                    className="nav-link"
                >
                    <Link
                        tabIndex={0}
                        role='link'
                        className='nav-links'
                        activeClass="active"
                        to="details-layout"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        Amenities
                    </Link>
                </motion.li>
                <motion.li
                    initial={false}
                    animate={isOpen ? "show" : "hide"}
                    variants={{
                        show: {
                            ...variants.show,
                            transition: { delay: 0.3, duration: 0.2 },
                        },
                        hide: {
                            ...variants.hide,
                            transition: { delay: 0.05, duration: 0.05 },
                        },
                    }}
                    className="nav-link"
                >
                    <Link
                        tabIndex={0}
                        role='link'
                        className='nav-links'
                        activeClass="active"
                        to="testimonials-layout"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        Testimonials
                    </Link>
                </motion.li>
                <motion.li
                    initial={false}
                    animate={isOpen ? "show" : "hide"}
                    variants={{
                        show: {
                            ...variants.show,
                            transition: { delay: 0.3, duration: 0.2 },
                        },
                        hide: {
                            ...variants.hide,
                            transition: { delay: 0.05, duration: 0.05 },
                        },
                    }}
                    className="nav-link"
                >
                    <Link
                        tabIndex={0}
                        role='link'
                        className='nav-links'
                        activeClass="active"
                        to="faq-layout"
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        FAQ
                    </Link>
                </motion.li>
            </ul>
        </nav>
    )
};

export default MenuContent;