import React, { useState } from 'react';
import '../../styles/faq/faq.scss';


const faqData = [
  {
    question: 'What are the steps to apply?',
    answer: "Fill out our interest form by clicking the link at the top. We’ll contact you within 1 day and you can let us know which house or room looks best to you. We'll then conduct a background check and after that can then send you a lease to review and sign."
  },
  {
    question: 'What are the steps after I sign my lease?   ',
    answer: "You’ll get an email from Doorloop.com to activate your account to be able to pay your rent and log maintenance requests. We’ll add you to a GroupMe with the other tenants in the house. The tenants that are moving out will also be in the chat if you want to ask them any questions! We’ll text and email you a house guide with the wifi password and a guide to food and experiences nearby! We’ll text and email you your front door code and private room code the day before your lease starts."
  },
  {
    question:"How fully furnished are the houses?",
    answer: "Some houses are fully furnished and some are just furnished in common areas. Please reference each house's description. Both furnishings include fully furnished common areas including small things like pots, pans, mixing bowls, tupperware, plates, utensils, and more. There are washers and dryers, fridges, stoves, ovens, microwaves, and smartTVs in every house as well. If the house is listed as fully furnished your room will also have a bed, desk, and sometimes dresser. The 3D tours are also entirely accurate to what you’ll have when you move in so you can always reference those."
  },
  {
    question:"Are there any leasing or applications fees?",
    answer: "Our only fee is $23.50 for a transunion background check, we don’t believe in charging any excess or unnecessary fees."
  },
  {
    question:"When are my first payments due after I sign a lease?",
    answer: "Nothing is due when you sign. Your refundable security deposit and first month’s rent are due by your move in date."
  },
  {
    question:"What if I move in halfway through the month?",
    answer: "We will prorate your rent and only charge you $425 for example if you move in on the 15th of January to a room that’s $850/month in rent. Since our leases are month to month, your lease in ongoing until you let us know you'd like to end it."
  },
  {
    question:"Are utilities included?",
    answer: "We include a monthly maid for all common spaces and bathrooms, lawn mowing, pest control, and trash and recycling pickup at no additional charge to you. Electric, water, gas, and high speed internet (500 mb/s) are an additional charge monthly based on usage. We will bill you through your tenant portal, you won’t have to set up anything yourself. Estimated charge is $60-90/month for electricity, water, gas, and high speed internet depending on the house and time of year."
  },
  {
    question:"How much parking is there at the houses?",
    answer: "There is unlimited street parking at all houses along with 2 garage door openers for garage spaces and of course 2 driveway spaces. We do not charge any additional fees for parking."
  },
  {
    question:"Are there houses for just girls or guys?",
    answer: "Yes, we have houses available that will only have female roommates, male, or co-ed. Just let us know your preference when you fill out the interest form!"
  }
];

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleFAQ = index => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div id='faq-layout'>
        <h1 id='faq-title'>Frequently Asked Questions</h1>
        <div className='faq-container'>
          {faqData.map((faq, index) => (
            <div key={index} className='faq-item'>
              <div
                className='faq-question'
                onClick={() => toggleFAQ(index)}
              >
                <p className='faq-text'>{faq.question}</p>
                <span className='faq-toggle'>{activeIndex === index ? '−' : '+'}</span>
              </div>
              <div
                className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
              >
                {faq.answer}
              </div>
              {index !== faqData.length - 1 && <hr />}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default FAQ;
  