import React from 'react';
import '../../styles/common/sitewide.scss';
import '../../styles/details/details.scss';

const DetailsItem = ({id, icon, header, text}) => {

    const Icon = icon;

    return(
        <div id={id} key={id} className="details-item-layout">
            <Icon className='box-icons'/>
            <h1 className='details-header'>{header}</h1>
            <p className='details-text'>{text}</p>
        </div>
    )
}

export default DetailsItem;
