import React from 'react';
import { Link } from "react-scroll";
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive'
import '../../styles/navbar/navbar.scss';
import HamburgerMenu from './HamburgerMenu'

const navLogo = require('../../assets/navbar/navLogo.webp')

const Navbar = () => {

    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (isMobile) {
            if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
               document.getElementById("mobile-nav-logo").style.visibility = "hidden"; 
            } else {
                document.getElementById("mobile-nav-logo").style.visibility = "visible"; 
            }
        } else {
            if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
                document.getElementById("navbar-content").style.height = "60px";
                document.getElementById("nav-logo").style.height = "50px";
            } else {
                document.getElementById("navbar-content").style.height = "12vh";
                document.getElementById("nav-logo").style.height = "10vh";
            }
        }
    }

    const isDesktopOrTablet = useMediaQuery({ query: '(min-width: 660px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 659px)' })

    return (
        <div id='navbar'>
            {isDesktopOrTablet &&
                <section id='navbar-content'>
                    <nav id='left-nav' className='links'>
                        <Link
                            tabIndex={0}
                            role='link'
                            className='nav-links'
                            aria-label=''
                            activeClass="active"
                            to="carousel-layout"
                            offset={-110}
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            <motion.p
                                className='p-text'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                whileInView={{ opacity: 1 }}
                            >
                                Rent
                            </motion.p>
                        </Link>
                        <Link
                            tabIndex={0}
                            role='link'
                            className='nav-links'
                            activeClass="active"
                            to="details-layout"
                            spy={true}
                            offset={-80}
                            smooth={true}
                            duration={500}
                        >
                            <motion.p
                                className='p-text'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                whileInView={{ opacity: 1 }}
                            >
                                Amenities
                            </motion.p>
                        </Link>
                    </nav>
                    <nav className='links'>
                        <Link
                            tabIndex={0}
                            role='link'
                            id='title'
                            activeClass="active"
                            to="hero-body"
                            offset={-300}
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            <motion.img
                                className='p-text'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                whileInView={{ opacity: 1 }}
                                src={navLogo}
                                alt='scroll to top'
                                id='nav-logo'></motion.img>
                        </Link>
                    </nav>
                    <nav id='right-nav' className='links'>
                        <Link
                            tabIndex={0}
                            role='link'
                            className='nav-links'
                            activeClass="active"
                            offset={-70}
                            to="testimonials-layout"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            <motion.p
                                className='p-text'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                whileInView={{ opacity: 1 }}
                            >
                                Testimonials
                            </motion.p>
                        </Link>
                        <Link
                            tabIndex={0}
                            role='link'
                            className='nav-links'
                            activeClass="active"
                            to="faq-layout"
                            offset={-110}
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            <motion.p
                                className='p-text'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                whileInView={{ opacity: 1 }}
                            >
                                FAQ
                            </motion.p>
                        </Link>
                    </nav>
                </section>
            }
            {isMobile &&
                <nav id='mobile-nav'>
                    <img
                        src={navLogo}
                        alt='Company logo'
                        id='mobile-nav-logo'>
                    </img>
                    <HamburgerMenu />
                </nav>
            }
        </div>

    )
}

export default Navbar;
