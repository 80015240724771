import React from 'react';
import '../../styles/testimonials/testimonials.scss';
import TestimonialCard from './TestimonialCard'
import { useMediaQuery } from 'react-responsive'

const bryan = require('../../assets/testimonials/bryanHeadshot.webp');
const hannah = require('../../assets/testimonials/hannah.webp');
const grant = require('../../assets/testimonials/grantHeadshot-min.webp');

const testimonialInfo = [
    {
        key: 'bryan',
        photo: bryan,
        text: '“Renting with .House was extremely easy and stress-free. The .House team are super responsive and do their best to accommodate any requests. I would highly recommend staying with them.”',
        name: 'Bryan',
        house: 'Cottingham House'
    },
    {
        key: 'hannah',
        photo: hannah,
        text: '“Living in a .House was the best decision I could\'ve made when working at Tesla. Their team is amazingly helpful and super responsive, and getting a built-in friend group was so fun!”',
        name: 'Hannah',
        house: 'Cottingham House'
    },
    {
        key: 'grant',
        photo: grant,
        text: '“The most challenging thing about moving is meeting new people. This is a great way to meet amazing people in a new environment. I loved my time here and highly recommend a .House.”',
        name: 'Grant',
        house: 'Tai Londo House'
    }
];

const testCards = testimonialInfo.map((obj) =>
        <TestimonialCard key={obj.key} id={obj.key} photo={obj.photo} text={obj.text} name={obj.name} house={obj.house} />
    );

const Testimonials = () => {

    const isDesktopOrTablet = useMediaQuery({ query: '(min-width: 660px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 659px)' })

    return(
        
        <div>
            {isDesktopOrTablet && 
                <div id='testimonials-layout'>
                    <h1 id='testimonials-title'>Testimonials</h1>
                    <div id='card-layout'>
                        {testCards}
                    </div>
                </div>
            }
            {isMobile && 
                <div id='testimonials-layout'>
                    <h1 id='testimonials-title'>Testimonials</h1>
                    <div id='card-layout'>
                        {testCards}
                    </div>
                </div>
            }
        </div>
        
    );
}


export default Testimonials;