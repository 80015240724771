import React from 'react';
import '../../styles/common/sitewide.scss';
import '../../styles/details/details.scss';
import DetailsItem from './DetailsItem';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
// import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';

const furnishHeader = 'Fully Furnished';
const simpleHeader = 'Flexible Leases';
// const friendlyHeader = 'Community Living';
const allInOneHeader = 'All-in-One Care'
// const conciergeHeader = 'Concierge'

const furnishText = 'Houses are ready to live in with special touches like smart TVs, air fryers, and backyard grills.';
const simpleText = 'Let us know the start date you want and how many months you\’d like to stay. We\'ll prorate the rent to the day you move in.';
// const friendlyText = 'Live with other interns at your company. Carpool to work, explore the city, and host house events as one intern squad.';
// const conciergeText = 'Indulge in personalized convenience and exceptional service with our dedicated concierge.'
const allInOneText = 'Comprehensive maintenance package at no additional cost, providing cleaning services, lawn care, and pest control.';

const Details = () => {
    return(           
        <div id="details-layout">
            <div id="details-inner-layout">
                <DetailsItem
                    key='furnish'
                    id='furnish'
                    icon={HomeOutlinedIcon}
                    header={furnishHeader}
                    text={furnishText} 
                />
                <DetailsItem
                    key='simple'
                    id='simple'
                    icon={CheckCircleOutlinedIcon}
                    header={simpleHeader}
                    text={simpleText} 
                />
                {/* <DetailsItem
                    key='friendly'
                    id='friendly'
                    icon={SentimentSatisfiedAltOutlinedIcon}
                    header={friendlyHeader}
                    text={friendlyText} 
                />
                <DetailsItem
                    key='concierge'
                    id='concierge'
                    icon={CallOutlinedIcon}
                    header={conciergeHeader}
                    text={conciergeText} 
                /> */}
                <DetailsItem
                    key='allInOne'
                    id='allInOne'
                    icon={BuildCircleOutlinedIcon}
                    header={allInOneHeader}
                    text={allInOneText} 
                />
            </div>
        </div>
    )
}

export default Details;
