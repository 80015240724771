import React from 'react';
import Navbar from './components/navbar/Navbar';
import HomePage from './components/homepage/Homepage';
import './styles/common/sitewide.scss';

const App = () => {
    return (
        <div>
            <Navbar />
            <HomePage />
        </div>
    );
}


export default App;