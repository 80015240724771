import React from 'react';
import { motion } from 'framer-motion';
import '../../styles/hero/hero.scss';
import { useMediaQuery } from 'react-responsive'

import bgImage from '../../assets/hero/colive204.webp'

const Hero = () => {

    const isDesktopOrTablet = useMediaQuery({ query: '(min-width: 661px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 660px)' })

    return(
        <div>
            {isDesktopOrTablet &&
                <div id='hero-body' style={{backgroundImage: `url(${bgImage})`}}>
                    <section id='left-hero'>
                        <section id='title-block'>
                            <h1 id='hero-text'>Find your home away from home</h1>
                            <motion.a 
                                id="title"
                                activeClass="active"
                                offset={-115}
                                spy={true}
                                smooth={true}
                                duration={500}
                                target="_blank"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                whileInView={{ opacity: 1 }} 
                                href="https://docs.google.com/forms/d/1_WorRLzkbO1oHMtC_6QUJEOEIlihsWU-m28zZnYwrCs/viewform?edit_requested=true">
                                <button id='rental-button'>
                                    Get matched with a coliving house
                                </button>
                            </motion.a>
                        </section>
                    </section>
                </div>
            }
            {isMobile && 
                <div id='hero-body' style={{backgroundImage: `url(${bgImage})`}}>
                <section id='left-hero'>
                    <section id='title-block'>
                        <h1 id='hero-text'>Find your home away from home</h1>
                        <motion.a  
                            id="title"
                            role='link'
                            activeClass="active"
                            offset={-45}
                            spy={true}
                            smooth={true}
                            duration={500}
                            target="_blank"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            whileInView={{ opacity: 1 }} 
                            href="https://docs.google.com/forms/d/1_WorRLzkbO1oHMtC_6QUJEOEIlihsWU-m28zZnYwrCs/viewform?edit_requested=true">
                            <button id='rental-button'>
                                Get matched with an coliving house
                            </button>
                        </motion.a >
                    </section>
                </section>
            </div>
            }
        </div>
        
    );
}


export default Hero;